import { gql } from 'apollo-boost'
export const CREATE_ORDER = gql`
	mutation createOrder(
		$product: [OrderItemInput]!
		$freight: [OrderItemInput]
		$subtotal: Int
		$tax: Int
		$total: Int
	) {
		createOrder(
			product: $product
			freight: $freight
			subtotal: $subtotal
			tax: $tax
			total: $total
		) {
			_id
			orderId
			date
			freight {
				name
				sku
				quantity
				price
			}
			product {
				name
				sku
				quantity
				price
			}
			subtotal
			tax
			total
			meta {
				createdAt
				updatedAt
			}
			log
		}
	}
`
export const UPDATE_ORDER = gql`
	mutation updateOrder(
		$_id: ID!
		$product: [OrderItemInput]!
		$freight: [OrderItemInput]
		$subtotal: Int
		$tax: Int
		$total: Int
		$payment: PaymentInput
		$shipping: OrderAddressInput
		$billing: OrderAddressInput
	) {
		updateOrder(
			_id: $_id
			product: $product
			freight: $freight
			subtotal: $subtotal
			tax: $tax
			total: $total
			payment: $payment
			shipping: $shipping
			billing: $billing
		) {
			_id
			orderId
			affiliateId
			consumer {
				_id
			}
			dealer {
				_id
			}
			date
			freight {
				name
				sku
				quantity
				price
			}
			product {
				name
				sku
				quantity
				price
			}
			fee {
				name
				sku
				quantity
				price
			}
			shipping {
				firstName
				lastName
				email
				phone
				address {
					fullAddress
					line1
					line2
					city
					state
					postalCode
					country
				}
			}
			billing {
				firstName
				lastName
				email
				phone
				address {
					fullAddress
					line1
					line2
					city
					state
					postalCode
					country
				}
			}
			subtotal
			tax
			total
			orderTotal
			payment {
				stripeCustomerId
				stripeSourceId
				stripeIntentId
				stripeChargeId
				cardType
				cardNum
			}
			meta {
				createdAt
				updatedAt
			}
			log
		}
	}
`
export const PAY_ORDER = gql`
	mutation payOrder(
		$_id: ID!
		$product: [OrderItemInput]
		$freight: [OrderItemInput]
		$coupon: [CouponInput]
		$fee: [OrderItemInput]
		$discount: Int
		$subtotal: Int
		$tax: Int
		$total: Int
		$orderTotal: Int
		$shipping: OrderAddressInput
		$billing: OrderAddressInput
		$payment: PaymentInput!
	) {
		payOrder(
			_id: $_id
			product: $product
			freight: $freight
			coupon: $coupon
			fee: $fee
			discount: $discount
			subtotal: $subtotal
			tax: $tax
			total: $total
			orderTotal: $orderTotal
			shipping: $shipping
			billing: $billing
			payment: $payment
		) {
			_id
			orderId
			affiliateId
			consumer {
				_id
			}
			dealer {
				_id
			}
			date
			freight {
				name
				sku
				quantity
				price
			}
			product {
				name
				sku
				quantity
				price
			}
			fee {
				name
				sku
				quantity
				price
			}
			coupon {
				name
				code
				product
				discount
			}
			shipping {
				firstName
				lastName
				email
				phone
				address {
					fullAddress
					line1
					line2
					city
					state
					postalCode
					country
				}
			}
			billing {
				firstName
				lastName
				email
				phone
				address {
					fullAddress
					line1
					line2
					city
					state
					postalCode
					country
				}
			}
			discount
			subtotal
			tax
			total
			orderTotal
			payment {
				stripeCustomerId
				stripeSourceId
				stripeIntentId
				stripeChargeId
				cardType
				cardNum
			}
			meta {
				createdAt
				updatedAt
			}
			log
		}
	}
`
export const COMPLETE_ORDER = gql`
	mutation completeOrder($_id: ID!) {
		completeOrder(_id: $_id)
	}
`
export const ORDERS = gql`
	query allOrders($limit: Int, $page: Int, $sort: SortInput, $query: String) {
		orders(limit: $limit, page: $page, sort: $sort, query: $query) {
			pageInfo {
				docsCount
				limit
				page
				pagesCount
				nextPage
				prevPage
				hasNextPage
				hasPrevPage
			}
			edges {
				_id
				orderId
				consumer {
					_id
					firstName
					lastName
					email
					phone
					address {
						fullAddress
					}
				}
				dealer {
					_id
					name
					email
					phone
					address {
						fullAddress
					}
					flag
				}
				date
				product {
					name
					sku
					quantity
					price
				}
				freight {
					name
					sku
					quantity
					price
				}
				fee {
					name
					sku
					quantity
					price
				}
				shipping {
					firstName
					lastName
					email
					phone
					address {
						fullAddress
						line1
						line2
						city
						state
						postalCode
						country
					}
				}
				billing {
					firstName
					lastName
					email
					phone
					address {
						fullAddress
						line1
						line2
						city
						state
						postalCode
						country
					}
				}
				subtotal
				tax
				total
				orderTotal
				payment {
					stripeCustomerId
					stripeSourceId
					stripeIntentId
					stripeChargeId
					cardType
					cardNum
				}
				meta {
					createdAt
					updatedAt
				}
				log
			}
		}
	}
`
export const ORDER = gql`
	query($_id: ID!) {
		order(_id: $_id) {
			_id
			orderId
			affiliateId
			consumer {
				_id
			}
			dealer {
				_id
				priorityId
				name
				email
				phone
				sms
				contactName
				social {
					website
					minisite
					placeId
					fbId
					igId
				}
				address {
					fullAddress
					line1
					line2
					city
					state
					postalCode
					country
				}
				logo
				location {
					type
					coordinates
				}
				meta {
					distance
					createdAt
					updatedAt
				}
				flag
				type
				franchise
				status
			}
			date
			freight {
				name
				sku
				quantity
				price
			}
			product {
				name
				sku
				quantity
				price
			}
			fee {
				name
				sku
				quantity
				price
			}
			shipping {
				firstName
				lastName
				email
				phone
				address {
					fullAddress
					line1
					line2
					city
					state
					postalCode
					country
				}
			}
			billing {
				firstName
				lastName
				email
				phone
				address {
					fullAddress
					line1
					line2
					city
					state
					postalCode
					country
				}
			}
			subtotal
			tax
			total
			orderTotal
			payment {
				stripeCustomerId
				stripeSourceId
				stripeIntentId
				stripeChargeId
				cardType
				cardNum
			}
			meta {
				createdAt
				updatedAt
			}
			log
		}
	}
`
