import React, { useState, useEffect } from 'react'
import MapGL, { FlyToInterpolator, Marker } from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'
import { easeCubic } from 'd3-ease'
import tw, { css, styled } from 'twin.macro'

import Pin from './pin'

const Mapbox = styled.div`
	${tw`w-full md:w-6/12 md:mr-1`}
	height: 50vh!important;
	min-height: 200px;
	max-height: 300px;
`
export default ({ data }) => {
	const [map, setMap] = useState({
		viewport: {
			width: 300,
			height: 300,
			longitude: 134.361,
			latitude: -25.609,
			zoom: 3,
		},
	})

	const onViewportChange = viewport =>
		setMap({
			viewport: { ...map.viewport, ...viewport },
		})

	useEffect(() => {
		const updateViewport = async () => {
			const { longitude, latitude, zoom } = await new WebMercatorViewport(
				map.viewport
			).fitBounds([data.dealer.coordinates, data.consumer.coordinates], {
				padding: 20,
				offset: [0, -50],
			})
			const viewport = {
				...map.viewport,
				longitude,
				latitude,
				zoom,
				transitionDuration: 5000,
				transitionInterpolator: new FlyToInterpolator(),
				transitionEasing: easeCubic,
			}
			await onViewportChange(viewport)
			return ''
		}
		if (data.consumer.coordinates) updateViewport()
	}, [data.dealer.coordinates, data.consumer.coordinates])
	return (
		<Mapbox>
			<MapGL
				{...map.viewport}
				{...map.settings}
				width="100%"
				height="100%"
				mapStyle="mapbox://styles/mapbox/light-v9"
				onViewportChange={onViewportChange}
				dragToRotate={false}
				mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
			>
				{data.consumer ? (
					<>
						<Marker
							longitude={
								data.consumer
									? data.consumer.coordinates[0]
									: map.consumer.longitude
							}
							latitude={
								data.consumer
									? data.consumer.coordinates[1]
									: map.consumer.latitude
							}
							offsetTop={-20}
							offsetLeft={-10}
						>
							<Pin size={20} color={tw`text-yellow-500`} />
						</Marker>
					</>
				) : (
					''
				)}
				{data.dealer ? (
					<>
						<Marker
							longitude={
								data.dealer ? data.dealer.coordinates[0] : map.dealer.longitude
							}
							latitude={
								data.dealer ? data.dealer.coordinates[1] : map.dealer.latitude
							}
							offsetTop={-20}
							offsetLeft={-10}
						>
							<Pin size={20} color={tw`text-blue-500`} />
						</Marker>
					</>
				) : (
					''
				)}
			</MapGL>
		</Mapbox>
	)
}
